import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaTrash, FaPlus } from 'react-icons/fa';

function ProxyModal({ isOpen, onClose }) {
  const [proxies, setProxies] = useState([]);
  const [newProxies, setNewProxies] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchProxies();
    }
  }, [isOpen]);

  const fetchProxies = async () => {
    try {
      const response = await axios.get('/api/proxies');
      setProxies(response.data);
    } catch (error) {
      console.error('Error fetching proxies:', error);
    }
  };

  const handleAddProxies = async () => {
    try {
      const response = await axios.post('/api/proxies', { proxies: newProxies });
      setProxies([...proxies, ...response.data]);
      setNewProxies('');
    } catch (error) {
      console.error('Error adding proxies:', error);
    }
  };

  const handleDeleteProxy = async (proxyId) => {
    try {
      await axios.delete(`/api/proxies/${proxyId}`);
      setProxies(proxies.filter(proxy => proxy.id !== proxyId));
    } catch (error) {
      console.error('Error deleting proxy:', error);
    }
  };

  const handleDeleteAllProxy = async () => {
    try {
      await axios.delete(`/api/allproxy`);
      setProxies([]);
    } catch (error) {
      console.error('Error deleting all proxies:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white w-full max-w-md mx-auto rounded-lg shadow-lg">
        <div className="p-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Управление прокси (HTTP/S)</h3>
          <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
            <FaTimes size={20} />
          </button>
          
          <div className="mb-6">
            <textarea
              value={newProxies}
              onChange={(e) => setNewProxies(e.target.value)}
              placeholder="Формат: address:port:username:password&#10;Каждый прокси с новой строки"
              className="w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleAddProxies}
              className="mt-2 w-full flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <FaPlus className="mr-2" />
              Добавить прокси
            </button>
          </div>
          
          <div className="mb-6">
            <h4 className="text-lg font-medium text-gray-700 mb-2">Список прокси</h4>
            <ul className="max-h-60 overflow-auto bg-gray-50 rounded-md">
              {proxies.map(proxy => (
                <li key={proxy.id} className="flex justify-between items-center px-4 py-3 border-b border-gray-200 last:border-b-0">
                  <span className="text-sm text-gray-600">{`${proxy.address}:${proxy.port}`}</span>
                  <button
                    onClick={() => handleDeleteProxy(proxy.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </li>
              ))}
            </ul>
            {proxies.length > 0 && (
              <button
                onClick={handleDeleteAllProxy}
                className="mt-4 w-full flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <FaTrash className="mr-2" />
                Удалить все прокси
              </button>
            )}
          </div>
          
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-200 text-gray-800 text-base font-medium rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProxyModal;