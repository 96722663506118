import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProxyUpdateModal from './ProxyUpdateModal';
import { motion } from 'framer-motion';
import { FaEdit, FaTrash, FaPlay, FaStop, FaSave, FaArrowLeft  } from 'react-icons/fa';
import Logs from './Logs';

function PoolDetails({ pool, onDelete, onBack }) {
  const [status, setStatus] = useState('stopped');
  const [isProxyUpdateModalOpen, setIsProxyUpdateModalOpen] = useState(false);
  const [selectedTdatadir, setSelectedTdatadir] = useState(null);
  const [accounts, setAccounts] = useState(pool.accounts);
  const [poolLink, setPoolLink] = useState(pool.link);
  const [message, setMessage] = useState('');
  const [intervalFrom, setIntervalFrom] = useState(pool.intervalFrom || 60);
  const [intervalTo, setIntervalTo] = useState(pool.intervalTo || 120);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchStatus();
    fetchPoolDetails();
    fetchPoolOkay();
    const intervalId = setInterval(() => {
      fetchStatus();
      fetchPoolOkay();
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [pool]);
  
  const fetchPoolOkay = async () => {
    try {
      const response = await axios.get(`/api/pools/${pool.id}`);
      setAccounts(response.data.accounts);
      setPoolLink(response.data.link);
    } catch (error) {
      console.error('Error fetching pool details:', error);
    } 
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleIntervalFromChange = (e) => {
    setIntervalFrom(Number(e.target.value));
  };

  const handleIntervalToChange = (e) => {
    setIntervalTo(Number(e.target.value));
  };

  const fetchStatus = async () => {
    try {
      const response = await axios.get(`/api/pools/${pool.id}/status`);
      setStatus(response.data.status);
    } catch (error) {
      console.error('Error fetching pool status:', error);
    }
  };



  const fetchPoolDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/pools/${pool.id}`);
      setIntervalFrom(response.data.intervalFrom);
      setIntervalTo(response.data.intervalTo);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error fetching pool details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePool = async () => {
    try {
      await axios.put(`/api/pools/${pool.id}`, { message, intervalFrom, intervalTo });
      fetchPoolDetails();
    } catch (error) {
      console.error('Error updating pool:', error);
    }
  };
  const handleDelete = async () => {
    try {
      await axios.delete(`/api/pools/${pool.id}`);
      onDelete(pool.id);
      onBack();
    } catch (error) {
      console.error('Error deleting pool:', error);
    }
  };

  const handleStartStop = async () => {
    try {
      if (status === 'stopped') {
        await axios.post(`/api/pools/${pool.id}/start`);
      } else {
        await axios.post(`/api/pools/${pool.id}/stop`);
      }
      fetchStatus();
    } catch (error) {
      console.error('Error starting/stopping pool:', error);
    }
  };

  const openProxyUpdateModal = (tdatadir) => {
    setSelectedTdatadir(tdatadir);
    setIsProxyUpdateModalOpen(true);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mt-8 bg-white shadow-lg rounded-lg overflow-hidden"
    >

    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 px-6 py-4 relative">
      <motion.button
        onClick={onBack}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white hover:text-blue-200 transition-colors duration-200 flex items-center space-x-2"
      >
        <FaArrowLeft />
        <span>Назад</span>
      </motion.button>
      <h3 className="text-2xl font-bold text-white text-center">Пул: {pool.name}</h3>
    </div>
      
      <div className="p-6 space-y-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Текущая ссылка</h4>
          <p className="text-blue-600 break-all">{poolLink}</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Сообщение</h4>
          {isLoading ? (
            <div className="animate-pulse bg-gray-200 h-40 rounded-lg"></div>
          ) : (
            <textarea
              value={message}
              onChange={handleMessageChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
              rows="10"
            />
          )}
        </div>
        
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Интервал (секунды)</h4>
          {isLoading ? (
            <div className="flex space-x-4">
              <div className="animate-pulse bg-gray-200 h-10 w-1/2 rounded-lg"></div>
              <div className="animate-pulse bg-gray-200 h-10 w-1/2 rounded-lg"></div>
            </div>
          ) : (
            <div className="flex space-x-4">
              <input
                type="number"
                value={intervalFrom}
                onChange={handleIntervalFromChange}
                className="w-1/2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                min="1"
                placeholder="От"
              />
              <input
                type="number"
                value={intervalTo}
                onChange={handleIntervalToChange}
                className="w-1/2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                min="1"
                placeholder="До"
              />
            </div>
          )}
        </div>


        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Аккаунты</h4>
          <ul className="bg-white border border-gray-200 rounded-lg divide-y divide-gray-200 max-h-60 overflow-y-auto">
            {accounts.map((account, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className={`p-3 flex items-center justify-between ${
                  account.isChannelOwner ? 'bg-red-50' : ''
                }`}
              >
                <span className="text-gray-700">@{account.username}</span>
                <button
                  onClick={() => openProxyUpdateModal(account.tdata)}
                  className="px-3 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition flex items-center space-x-1"
                >
                  <FaEdit size={12} />
                  <span>Прокси</span>
                </button>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-3">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleDelete}
          disabled={status !== 'stopped'}
          className={`flex items-center space-x-2 py-2 px-4 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            status === 'stopped'
              ? 'bg-red-500 hover:bg-red-600 focus:ring-red-500'
              : 'bg-red-300 cursor-not-allowed'
          }`}
        >
          <FaTrash size={14} />
          <span>Удалить пул</span>
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleStartStop}
          className={`flex items-center space-x-2 py-2 px-4 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            status === 'stopped'
              ? 'bg-green-500 hover:bg-green-600 focus:ring-green-500'
              : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500'
          }`}
        >
          {status === 'stopped' ? <FaPlay size={14} /> : <FaStop size={14} />}
          <span>{status === 'stopped' ? 'Начать' : 'Стоп'}</span>
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleUpdatePool}
          className="flex items-center space-x-2 py-2 px-4 rounded-full text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <FaSave size={14} />
          <span>Обновить пул</span>
        </motion.button>
      </div>
      <Logs poolId={pool.id} />
      <ProxyUpdateModal
        isOpen={isProxyUpdateModalOpen}
        onClose={() => setIsProxyUpdateModalOpen(false)}
        tdatadir={selectedTdatadir}
      />
    </motion.div>
  );
}

export default PoolDetails;
