import React, { useState } from 'react';
import axios from 'axios';

function Auth({ onAuth }) {
  const [sessionString, setSessionString] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth', { sessionString });
      if (response.data.success) {
        localStorage.setItem('auth', 'true');
        onAuth(true);
      } else {
        setError('Неверная строка сессии');
      }
    } catch (error) {
      setError('Ошибка при авторизации');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Введите строку сессии</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={sessionString}
            onChange={(e) => setSessionString(e.target.value)}
            className="border p-2 rounded w-full mb-4"
            placeholder="Строка сессии"
          />
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Авторизоваться
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Auth;
