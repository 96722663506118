import React, { useState } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild, DialogPanel } from '@headlessui/react';
import { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';

function PoolModal({ isOpen, onClose, onSubmit }) {
  const [poolData, setPoolData] = useState({
    name: '',
    link: '',
    pattern: '',
    timestart: '',
    timeend: '',
    message: '',
    file: null,
    initiate: false,
    channelName: '',
    avatar: null,
  });
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setPoolData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    for (const key in poolData) {
      if (key === 'file' || key === 'avatar') {
        if (poolData[key]) {
          formData.append(key, poolData[key]);
        }
      } else {
        formData.append(key, poolData[key]);
      }
    }

    try {
      const response = await axios.post('/api/pools', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      onSubmit(poolData);
      onClose();
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Создание пула
                </DialogTitle>
                <button
                  onClick={onClose}
                  className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                >
                  <FaTimes />
                </button>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Имя пула</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Желательно только латинские буквы"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="link" className="block text-sm font-medium text-gray-700">Ссылка на канал</label>
                    <input
                      type="text"
                      id="link"
                      name="link"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="pattern" className="block text-sm font-medium text-gray-700">Паттерн</label>
                    <input
                      type="text"
                      id="pattern"
                      name="pattern"
                      placeholder="Например, kukuruza_"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div className="flex space-x-4">
                    <div className="flex-1">
                      <label htmlFor="timestart" className="block text-sm font-medium text-gray-700">От (сек)</label>
                      <input
                        type="number"
                        id="timestart"
                        name="timestart"
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        required
                      />
                    </div>
                    <div className="flex-1">
                      <label htmlFor="timeend" className="block text-sm font-medium text-gray-700">До (сек)</label>
                      <input
                        type="number"
                        id="timeend"
                        name="timeend"
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">Сообщение</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      placeholder="Пост в канале"
                    />
                  </div>
                  <div>
                    <label htmlFor="file" className="block text-sm font-medium text-gray-700">Файл</label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      onChange={handleChange}
                      className="mt-1 block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                      required
                    />
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="initiate"
                      name="initiate"
                      checked={poolData.initiate}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="initiate" className="ml-2 block text-sm text-gray-900">
                      Обряд инициации
                    </label>
                </div>
                    {poolData.initiate && (
                  <>
                    <div>
                      <label htmlFor="channelName" className="block text-sm font-medium text-gray-700">Имя канала</label>
                      <input
                        type="text"
                        id="channelName"
                        name="channelName"
                        value={poolData.channelName}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        required={poolData.initiate}
                      />
                    </div>
                    <div>
                      <label htmlFor="avatar" className="block text-sm font-medium text-gray-700">Аватарка канала</label>
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        onChange={handleChange}
                        className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                        accept="image/*"
                        required={poolData.initiate}
                      />
                    </div>
                  </>
                )}

            {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${uploadProgress}%`}}></div>
                    </div>
                  )}
                    <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    disabled={uploadProgress > 0 && uploadProgress < 100}
                  >
                    {uploadProgress > 0 && uploadProgress < 100 ? `Uploading... ${uploadProgress}%` : 'Создать пул'}
                  </button>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PoolModal;