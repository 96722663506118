import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Logs({ poolId }) {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchLogs();
    const interval = setInterval(fetchLogs, 1000); // Обновление каждые 5 секунд
    return () => clearInterval(interval); // Очистка интервала при размонтировании
  }, [poolId]);

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`/api/pools/${poolId}/logs`);
      setLogs(response.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const handleClearLogs = async () => {
    try {
      await axios.delete(`/api/pools/${poolId}/logs`);
      setLogs([]); // Очистка состояния логов после удаления
    } catch (error) {
      console.error('Error clearing logs:', error);
    }
  };

  return (
    <div className="mt-4 bg-white overflow-hidden">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Логи
        </h3>
        <button
          onClick={handleClearLogs}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Очистить логи
        </button>
      </div>
      <div className="border-t border-gray-200 logs-container">
        <ul className="divide-y divide-gray-200">
          {logs.map((log, index) => (
            <li key={index} className="px-4 py-4 sm:px-6">
              <div className="text-sm text-gray-900">{log.timestamp}</div>
              <div className="mt-1 text-sm text-gray-500">{log.message}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Logs;
