import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/24/solid';
import PoolModal from './components/PoolModal';
import PoolCard from './components/PoolCard';
import PoolDetails from './components/PoolDetails';
import ProxyModal from './components/ProxyModal';
import Auth from './components/Auth'; 

function App() {
  const [pools, setPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [isPoolModalOpen, setIsPoolModalOpen] = useState(false);
  const [isProxyModalOpen, setIsProxyModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [poolStatuses, setPoolStatuses] = useState({});


  const handleBack = () => {
    setSelectedPool(null);
  };

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth) {
      setIsAuthenticated(true);
    }

  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchPools = async () => {
        const response = await axios.get('/api/pools');
        setPools(response.data);
      };
      fetchPools();
      fetchPoolStatuses();
      const intervalId = setInterval(() => {
        fetchPools();
        fetchPoolStatuses();
      }, 2000);
  
      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated, pools]);

  const fetchPoolStatuses = async () => {
    try {
      const statusPromises = pools.map(pool => 
        axios.get(`/api/pools/${pool.id}/status`)
          .then(response => {
            return { id: pool.id, status: response.data.status };
          })
          .catch(error => {
            return { id: pool.id, status: 'error' };
          })
      );
      const statusResults = await Promise.all(statusPromises);
      const statuses = statusResults.reduce((acc, { id, status }) => {
        acc[id] = status;
        return acc;
      }, {});

      setPoolStatuses(statuses);
    } catch (error) {

    }
  };
  const handleCreatePool = async (poolData) => {
    try {
      const formData = new FormData();
      Object.keys(poolData).forEach(key => formData.append(key, poolData[key]));
      
      const response = await axios.post('/api/pools', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setPools([...pools, response.data]);
      setIsPoolModalOpen(false);
    } catch (error) {
      console.error('Error creating pool:', error);
    }
  };

  const handleDeletePool = async (poolId) => {
    try {
      await axios.delete(`/api/pools/${poolId}`);
      setPools(pools.filter(pool => pool.id !== poolId));
      setSelectedPool(null);
    } catch (error) {
      console.error('Error deleting pool:', error);
    }
  };

  const handleAuth = (authStatus) => {
    setIsAuthenticated(authStatus);
  };

  if (!isAuthenticated) {
    return <Auth onAuth={handleAuth} />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-10">
      <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">телебрат</h1>
            <div>
              <button
                onClick={() => setIsPoolModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Пул
              </button>
              <button
                onClick={() => setIsProxyModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Прокси
              </button>
            </div>
          </div>
        </header>
        <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            {selectedPool ? (
              <PoolDetails
                pool={selectedPool}
                onDelete={handleDeletePool}
                onBack={handleBack}
              />
            ) : (
              <div className="flex flex-wrap -mx-4">
                {pools.map(pool => (
                  <PoolCard
                    key={pool.id}
                    pool={pool}
                    status={poolStatuses[pool.id] || 'stopped'}
                    isSelected={selectedPool && selectedPool.id === pool.id}
                    onClick={() => setSelectedPool(pool)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
      </div>
      <PoolModal
        isOpen={isPoolModalOpen}
        onClose={() => setIsPoolModalOpen(false)}
        onSubmit={handleCreatePool}
      />
      <ProxyModal
        isOpen={isProxyModalOpen}
        onClose={() => setIsProxyModalOpen(false)}
      />
    </div>
  );
}

export default App;
