import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProxyUpdateModal({ isOpen, onClose, tdatadir }) {
  const [proxies, setProxies] = useState([]);
  const [selectedProxy, setSelectedProxy] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchProxies();
    }
  }, [isOpen]);

  const fetchProxies = async () => {
    try {
      const response = await axios.get('/api/proxies');
      setProxies(response.data);
    } catch (error) {
      console.error('Error fetching proxies:', error);
    }
  };

  const handleUpdateProxy = async () => {
    try {
      await axios.put(`/api/tdatadir/${tdatadir}/proxy`, { proxy_id: selectedProxy });
      onClose();
    } catch (error) {
      console.error('Error updating proxy:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Изменить прокси для {tdatadir}</h3>
          <div className="mt-2 px-7 py-3">
            <select
              value={selectedProxy}
              onChange={(e) => setSelectedProxy(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Выберите прокси</option>
              {proxies.map(proxy => (
                <option key={proxy.id} value={proxy.id}>
                  {`${proxy.address}:${proxy.port}`}
                </option>
              ))}
            </select>
            <button
              onClick={handleUpdateProxy}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Обновить
            </button>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProxyUpdateModal;
