import React from 'react';
import { FaUser, FaLink, FaClock, FaPlayCircle, FaStopCircle } from 'react-icons/fa';

function PoolCard({ pool, status, isSelected, onClick }) {
  const isRunning = status && status.includes('run');

  return (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8">
      <div
        className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl ${
          isSelected ? 'ring-2 ring-green-500' : ''
        } ${isRunning ? 'border-l-4 border-blue-500' : ''}`}
        onClick={onClick}
      >
        <div className={`p-4 flex justify-between items-center ${
          isRunning ? 'bg-gradient-to-r from-blue-500 to-blue-600' : 'bg-gradient-to-r from-gray-500 to-gray-600'
        }`}>
          <h2 className="text-xl font-bold text-white truncate">{pool.name}</h2>
          {isRunning ? (
            <FaPlayCircle className="text-white text-xl animate-pulse" />
          ) : (
            <FaStopCircle className="text-white text-xl" />
          )}
        </div>
        <div className="p-6">
          <div className="flex items-center mb-3">
            <FaUser className="text-gray-500 mr-2" />
            <p className="text-gray-700">
              {pool.accounts.length} аккаунт{pool.accounts.length !== 1 ? 'ов' : ''}
            </p>
          </div>
          <div className="flex items-center mb-3">
            <FaLink className="text-gray-500 mr-2" />
            <p className="text-gray-700 truncate">{pool.link || 'Нет ссылки'}</p>
          </div>
          <div className="flex items-center">
            <FaClock className="text-gray-500 mr-2" />
            <p className="text-gray-700">
              {pool.intervalFrom} - {pool.intervalTo} сек.
            </p>
          </div>
        </div>
        <div className={`h-1 ${
          isRunning ? 'bg-blue-500' : isSelected ? 'bg-green-500' : 'bg-gray-200'
        }`}></div>
      </div>
    </div>
  );
}

export default PoolCard;